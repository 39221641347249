<template>
  <div class="main-banner-container">
    <div class="grid-container mx-auto">
      <div class="main-banner grid gap-x-4 grid-cols-1 xl:grid-cols-12">
        <div
          class="banner-incentive-container banner-image-container xl:col-span-6 xxl:col-span-7 xl:order-2"
        >
          <picture>
            <source
              :srcset="helperImage.optimise(contentData.foregroundImageDesktop)"
              media="(min-width: 1024px)"
              :width="contentData.foregroundImageDesktop?.width || 'auto'"
              :height="contentData.foregroundImageDesktop?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <source
              :srcset="helperImage.optimise(contentData.foregroundImageTablet)"
              media="(min-width: 768px)"
              :width="contentData.foregroundImageTablet?.width || 'auto'"
              :height="contentData.foregroundImageTablet?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <img
              :src="helperImage.optimise(contentData.foregroundImageMobile)"
              :alt="contentData.foregroundImageDesktop?.description || 'Banner Image'"
              :width="contentData.foregroundImageMobile?.width || 'auto'"
              :height="contentData.foregroundImageMobile?.height || 'auto'"
              class="banner-image"
              fetchpriority="high"
              loading="eager"
            />
          </picture>
          <CoverIncentive
            v-if="contentData.incentive"
            :content="contentData.incentive"
            :class="contentData.incentiveLocation"
          />
        </div>
        <div
          class="banner-content flex flex-col justify-center gap-4 px-4 py-6 xxl:px-6 xl:col-span-6 xxl:col-span-5"
        >
          <AppointmentSelector
            v-if="contentData.appointmentForm && contentData.type === 'appointment'"
            :content="contentData.appointmentForm"
            :banner-content="contentData.bannerContent"
            :trust-pilot-widget="contentData.trustPilotWidget"
          />
          <MultiStepForm
            v-else-if="contentData.multiStepForm && contentData.type === 'step_banner'"
            :content="contentData.multiStepForm"
            wrapper-class="multistep-quote-container banner"
          />
          <BannerContent
            v-else
            :content="contentData.bannerContent"
            :trust-pilot-widget="contentData.trustPilotWidget"
            :gtm="gtm.heroBannerCTA"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { helperImage } from '#root/utils/helpers'
import getBannerContent from '#root/components/composables/getBannerContent.js'
import MultiStepForm from '#root/components/forms/multi-step/MultiStepForm.vue'
import BannerContent from '#root/components/banners/BannerContent.vue'
import CoverIncentive from '#root/components/pageblocks/CoverIncentive.vue'
import AppointmentSelector from '#root/components/pageblocks/AppointmentSelector.vue'

const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})

const { gtm, contentData } = getBannerContent(props)
</script>

<style lang="scss">
// Container reset
.site-bsia {
  .main-banner-container {
    background: $white;
    width: 100%;
    overflow-x: hidden;

    .grid-container {
      max-width: unset;
      width: unset;

      @include media-query('desktop') {
        width: $g-desktop-breakpoint-min-width;
      }

      @include media-query('desktop-large') {
        width: $g-desktop-large-breakpoint-min-width;
      }
    }

    // Conatiner paddings
    @include media-query('desktop') {
      .main-banner {
        padding: 48px 16px;
      }

      .banner-content {
        padding: $spacing-10 0;
      }
    }

    @include media-query('desktop-large') {
      .main-banner {
        padding: $spacing-12 $spacing-6;
      }

      .banner-content {
        padding: 0;
      }
    }

    .banner-content {
      width: 100%;
      margin: 0 auto;

      @include media-query('phablet') {
        max-width: $g-phablet-breakpoint-min-width;
      }

      @include media-query('tablet') {
        max-width: 100%;
        width: $g-tablet-breakpoint-min-width;
      }

      @include media-query('desktop') {
        max-width: unset;
        width: unset;
      }
    }

    // Banner Image styles
    .main-banner {
      .banner-image-container {
        height: 300px;
        position: relative;
        overflow: visible;

        @include media-query('phablet') {
          height: 400px;
        }

        @include media-query('tablet') {
          height: 456px;
        }

        @include media-query('desktop') {
          height: 488px;
          width: auto;
        }

        @include media-query('desktop-large') {
          height: 608px;
        }

        .banner-image {
          width: 100%;
          object-fit: cover;
          object-position: top;
          display: block;
          height: 100%;

          @include media-query('desktop') {
            height: 100%;
            width: auto;
            position: absolute;
            top: 0;
            left: 24px;
          }
        }
      }

      // Incentive styles
      .incentive-container {
        img {
          height: 144px;
          width: auto;
          position: absolute;
          right: 16px;
          bottom: -23px;
        }

        @include media-query('phablet') {
          img {
            height: 217px;
          }
        }

        @include media-query('desktop') {
          img {
            right: 0;
            bottom: -32px;
            width: 252px;
            height: auto;
          }
        }

        @include media-query('desktop-large') {
          img {
            top: 0;
            bottom: unset;
            width: 242px;
          }
        }
      }
    }
  }

  .VWO-047 {
    .main-banner-container {
      .main-banner {
        .banner-content {
          .title {
            h1 {
              font-weight: 900;

              strong {
                font-weight: 900;
              }
            }
          }
        }

        .banner-image-container {
          height: 100%;

          @include media-query('phablet') {
            height: 400px;
          }

          @include media-query('tablet') {
            height: 456px;
          }

          @include media-query('desktop') {
            height: 488px;
            width: auto;
          }

          @include media-query('desktop-large') {
            height: 608px;
          }
        }

        .banner-incentive-container {
          .banner {
            img {
              height: 154px;
            }
          }

          @include media-query('phablet') {
            .banner {
              img {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
