<template>
  <div :class="[props.class, 'product-card', 'mb-10']">
    <div class="image">
      <ContentImage :content="contentData.images" />
    </div>
    <TextContent :content="contentData.content" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'

import ContentImage from '#root/components/pageblocks/ContentImage.vue'
import TextContent from '#root/components/pageblocks/TextContent.vue'

const props = defineProps({
  class: {
    type: String,
    default: ''
  },
  content: {
    type: Object,
    required: true
  }
})

const contentData = computed(() => {
  return {
    content: useGetContent(props.content, 'content.linkedItems[0].elements', null),
    images: useGetContent(props.content, 'image.linkedItems[0].elements', null),
    ctas: useGetContent(props.content, 'ctas.linkedItems', null)
  }
})
</script>

<style lang="scss">
.product-card {
  background: $white;
  box-shadow: $g-box-shadow;
  border-radius: $g-radius;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    .image {
      img {
        transform: scale(1.1);
        transition: transform 0.4s ease-in-out;
      }
    }
  }

  .image {
    overflow: hidden;

    img {
      transition: transform 0.3s ease;
      width: 100%;
      height: 100%;
      max-height: 395px;
      min-height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .text-container {
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $spacing-4;

    .text-title {
      margin: 0;
    }

    .m_ticks {
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: $spacing-4;

      ul {
        margin: 0 0 0 $spacing-9;

        li {
          margin-bottom: $spacing-2;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-6;
      margin-top: $spacing-2;

      > div,
      .cta-btn {
        width: 100%;
      }

      @include media-query('phablet') {
        > div,
        .cta-btn {
          width: auto;
        }
      }
    }

    .cta-btn {
      margin: 0;
    }
  }

  &.primary-product {
    .image {
      .image-container {
        width: auto;
        height: 395px;
      }
    }
  }

  &.secondary-product {
    .image {
      .image-container {
        width: auto;
        height: 253px;
      }
    }
  }

  @include media-query('tablet') {
    &.primary-product {
      .image {
        .image-container {
          height: 100%;
          height: 395px;
        }
      }
    }

    &.secondary-product {
      display: flex;
      flex-direction: row-reverse;

      .text-container {
        width: 50%;
      }

      .image {
        width: 50%;

        .image-container {
          height: 100%;
          position: relative;
          overflow: hidden;
        }
      }
    }
  }

  @include media-query('desktop') {
    &.primary-product {
      display: flex;
      flex-direction: row;

      .text-container {
        width: 50%;
      }

      .image {
        width: 50%;

        .image-container {
          height: 100%;
          max-height: initial;
        }
      }
    }

    &.secondary-product {
      display: flex;
      flex-direction: row-reverse;

      .text-container {
        width: 50%;
      }

      .image {
        width: 50%;

        .image-container {
          height: 100%;
        }
      }
    }
  }
}

.product-card.vertical-product-card {
  .text-container {
    padding: $spacing-6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .text-content {
      margin: 0;
    }

    .m_ticks {
      margin: 0 0 $spacing-4;
    }
  }
}
</style>
